.list > div {
  width: 200px;
  float: left;
  height: 300px;
  background: #fff;
  margin: 1%;
  position: relative;
}
.product > div {
  text-align: center;
  position: absolute;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  bottom: 0;
  height: 0;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}
.product:hover > div {
  position: absolute;
  height: 300px;
  background: rgba(255, 255, 255, 0.5);
}
.product > img {
  margin: 0 auto;
  display: block;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.product:hover > img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.product > div > h2 {
  border-bottom: 1px solid #818181;
  padding: 0 0 1rem;
}
.descr {
  font-size: 0.8rem;
  padding: 2rem;
  line-height: 1.2rem;
}
.product > div > a > p {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1rem;
  line-height: 2rem;
  background: #000;
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0 auto !important;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.product > div > a > p:hover {
  background: linear-gradient(to right, #ff6a00, #ee0979);
  bottom: 5px;
  letter-spacing: 4px;
}
